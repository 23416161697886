.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
  &:hover {
    color: #1890ff;
  }
}

.ant-layout {
  min-height: 100vh;
}

.site-layout {
  min-height: 100vh;
  .site-layout-background {
    background: #fff;
  }
  .main-content-warp {
    margin: 24px 16px 0;
    padding: 24px;
    min-height: 280px;
  }
}

.header {
  display: flex;
  justify-items: center;
  justify-content: space-between;
  .right-content {
    display: flex;
    justify-items: center;
    justify-content: space-between;
    padding: 0 16px;
    .user {
      margin-left: 10px;
    }
  }
}
