@import '~antd/dist/antd.css';

.site-tag-plus {
  background: #fff;
  border-style: dashed;
}
.edit-tag {
  user-select: none;
}
.tag-input {
  width: 78px;
  margin-right: 8px;
  vertical-align: top;
}
  [data-theme="dark"] .site-tag-plus {
    background: transparent;
    border-style: dashed;
  }
