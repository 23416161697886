.ck-editor__editable_inline {
  max-height: 600px;
  overflow: scroll;
}

.ck-editor__main {
  .ck-source-editing-area {
    max-width: 100%;
    max-height: 600px;
    display: block;
    &::after {
      display: none;
    }

    textarea {
      width: 100%;
      max-height: 600px;
      height: 600px;
      overflow-y: scroll;
    }

  }

  h1,
  h1 span {
    font-size: 25px !important;
    font-weight: bold;
  }
  h2,
  h2 span {
    font-size: 23px !important;
    font-weight: bold;
  }
  h3,
  h3 span {
    font-size: 22px !important;
    font-weight: bold;
  }
  h4,
  h4 span {
    font-size: 21px !important;
    font-weight: bold;
  }
}
