.logo {
  color: #fff;
  font-weight: bold;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  height: 64px;
  font-size: 18px;
}
