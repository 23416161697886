.register-page {
}

.register-form {
  max-width: 600px;
  margin: 0 auto;
}

.register-form-button {
  width: 100%;
}
